<template>
    <div v-htmltit data-title="拍照订货">
        <go-back-btn></go-back-btn>
        <div class="add-photo-order">
            <div class="add-photo-tip">还可上传{{ 9 - fileList.length}}张</div>
            <div class="upload-list">
                <van-uploader 
                    :disabled="disabled"
                    :max-count="9" 
                    v-model="fileList" 
                    :after-read="onRead"
                    @oversize="onOversize"
                    @delete="handleDelImg"
                    accept="image/*">
                </van-uploader>
            </div>
            <div>备注（可选）</div>
            <div>
                <van-field
                    v-model="message"
                    rows="2"
                    autosize
                    type="textarea"
                    maxlength="100"
                    placeholder="请输入备注"
                    show-word-limit
                    />
            </div>
        </div>
        <div class="add-photo-btn" @click="handleSubmit">
            提交
        </div>
    </div>
</template>
<script>
import "./index.less"
import Exif from "exif-js"
import {isEmojiCharacter} from "@/libs/utils"
import goBackBtn from "@/view/Public/goBackBtn"
import { recordUploadfile, recordCreate } from "@/apis/api"
export default {
    name:"add-photo-order",
    components:{goBackBtn },
    data(){
        return{
            isAdd:false,
            reqFlag:true,
            disabled:false,
            fileList:[], //前台展示的图片
            imgList:[],
            files: {
                name: "",
                type: ""
            },
            headerImage: null,
            picValue: null,
            upImgUrl:"",
            message:""
        }
    },
    beforeRouteLeave(to,from,next){
        if(this.isAdd){
            if(to.name == 'photo-order'){
                to.meta.isfresh = true
            }
        }else{
            if(to.name == 'photo-order'){
                to.meta.isfresh = false
            }
        }
        next()
    },
    methods:{
        //过滤上传图片过大
        onOversize(file) {
            this.$toast("文件过大，请上传20M以下的文件");
        },
        handleDelImg(file,name){
            this.imgList.splice(name.index,1)
        },
      // 组件方法 获取 流
        async onRead(file) {
            this.files.name = file.file.name; // 获取文件名
            this.files.type = file.file.type; // 获取类型
            this.picValue = file.file; // 文件流

            file.status = 'uploading';
            file.message = '上传中...';
            this.imgPreview(this.picValue,file);
        },
        handleSubmit(){
            if(!this.reqFlag){
                this.$toast("图片上传中，请稍后")
                return false
            }
            if(this.imgList.length === 0){
                this.$toast("请上传图片")
                return false
            }
            if(isEmojiCharacter(this.message)){
                this.$toast("不可以输入表情")
                return false
            }
            let toast = this.$toast.loading({
                message: '创建中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            let params = {
                img_list:this.imgList,
                remarks:this.message
            }
            recordCreate(params).then(res=>{
                toast.clear();
                if(res.code == 0){
                    this.$toast("创建成功")
                    this.isAdd = true
                    this.$router.push({
                        name:"photo-order"
                    })
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        // 处理图片
        imgPreview(file, updataState) {
            let self = this;
            let Orientation;
            //去获取拍照时的信息，解决拍出来的照片旋转问题
            Exif.getData(file, function () {
                Orientation = Exif.getTag(this, "Orientation");
            });
            // 看支持不支持FileReader
            if (!file || !window.FileReader) return;
            if (/^image/.test(file.type)) {
                // 创建一个reader
                let reader = new FileReader();
                // 将图片2将转成 base64 格式
                reader.readAsDataURL(file);
                // 读取成功后的回调
                reader.onloadend = function () {
                // console.log(this.result);
                let result = this.result;
                let img = new Image();
                img.src = result;
                //判断图片是否大于1MB,是就直接上传，反之压缩图片
                if (this.result.length <= 1024 * 1024) {
                    self.headerImage = this.result;
                    self.postImg(updataState);
                } else {
                    img.onload = function () {
                        let data = self.compress(img, Orientation);
                        self.headerImage = data;
                        self.postImg(updataState);
                    };
                }
                };
            }
        },
        // 压缩图片
        compress(img, Orientation) {
            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            //瓦片canvas
            let tCanvas = document.createElement("canvas");
            let tctx = tCanvas.getContext("2d");
            // let initSize = img.src.length;
            let width = img.width;
            let height = img.height;
            //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
            let ratio;
            if ((ratio = (width * height) / 4000000) > 1) {
                // console.log("大于400万像素");
                ratio = Math.sqrt(ratio);
                width /= ratio;
                height /= ratio;
            } else {
                ratio = 1;
            }
            canvas.width = width;
            canvas.height = height;
            //    铺底色
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            //如果图片像素大于100万则使用瓦片绘制
            let count;
            if ((count = (width * height) / 1000000) > 1) {
                // console.log("超过100W像素");
                count = ~~(Math.sqrt(count) + 1); //计算要分成多少块瓦片
                //      计算每块瓦片的宽和高
                let nw = ~~(width / count);
                let nh = ~~(height / count);
                tCanvas.width = nw;
                tCanvas.height = nh;
                for (let i = 0; i < count; i++) {
                for (let j = 0; j < count; j++) {
                    tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh);
                    ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
                }
                }
            } else {
                ctx.drawImage(img, 0, 0, width, height);
            }
            //修复ios上传图片的时候 被旋转的问题
            // if (Orientation != "" && Orientation != 1) {
            //     switch (Orientation) {
            //     case 6: //需要顺时针（向左）90度旋转
            //         this.rotateImg(img, "left", canvas);
            //         break;
            //     case 8: //需要逆时针（向右）90度旋转
            //         this.rotateImg(img, "right", canvas);
            //         break;
            //     case 3: //需要180度旋转
            //         this.rotateImg(img, "right", canvas); //转两次
            //         this.rotateImg(img, "right", canvas);
            //         break;
            //     }
            // }
            //进行最小压缩
            let ndata = canvas.toDataURL("image/jpeg", 0.35);
            tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
            return ndata;
        },
        // 旋转图片
        rotateImg(img, direction, canvas) {
            //最小与最大旋转方向，图片旋转4次后回到原方向
            const min_step = 0;
            const max_step = 3;
            if (img == null) return;
            //img的高度和宽度不能在img元素隐藏后获取，否则会出错
            let height = img.height;
            let width = img.width;
            let step = 2;
            if (step == null) {
                step = min_step;
            }
            if (direction == "right") {
                step++;
                //旋转到原位置，即超过最大值
                step > max_step && (step = min_step);
            } else {
                step--;
                step < min_step && (step = max_step);
            }
            //旋转角度以弧度值为参数
            let degree = (step * 90 * Math.PI) / 180;
            let ctx = canvas.getContext("2d");
            switch (step) {
                case 0:
                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0);
                break;
                case 1:
                canvas.width = height;
                canvas.height = width;
                ctx.rotate(degree);
                ctx.drawImage(img, 0, -height);
                break;
                case 2:
                canvas.width = width;
                canvas.height = height;
                ctx.rotate(degree);
                ctx.drawImage(img, -width, -height);
                break;
                case 3:
                canvas.width = height;
                canvas.height = width;
                ctx.rotate(degree);
                ctx.drawImage(img, -width, 0);
                break;
            }
        },
        //将base64转换为文件
        dataURLtoFile(dataurl) {
            var arr = dataurl.split(","),
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], this.files.name, {
                type: this.files.type
            });
        },
        //这里写接口 
        async postImg(fileTemp) {
            this.reqFlag = false
            this.disabled = true
            let file = this.dataURLtoFile(this.headerImage);
            let formData = new window.FormData();
            formData.append("file", file);
            recordUploadfile(formData).then(res=>{
                if(res.code == 0){
                    fileTemp.status = 'success';
                    fileTemp.message = '上传成功';
                    this.imgList.push(res.res.urls)
                    this.reqFlag = true
                    this.disabled = false
                }else{
                    this.$toast(res.msg)
                }
            })
        }
    }
}
</script>